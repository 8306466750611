<!--协会荣誉-->
<template>
  <div class="hoverDiv">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="hoverClass">
      <div class="notice-title">
        <span>协会荣誉</span>
      </div>
      <div class="honorContent">
        <div class="honorList cursorPointer" v-for="(item, index) in honorList" :key="index" @click="toDetail(item)">
          <div class="honorImg">
            <img :src="item.contentImg" alt="">
          </div>
          <div class="honorName">
            <div class="title">{{item.contentTitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[12, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 10px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "honorDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '协会荣誉',
          path: '/honor/honorDetail'
        },
      ],
      honorList: [],
      page: 1,
      tableTotal: 0,
      pageSize: 12,
    }
  },
  methods: {
    //查询详情
    getHonorDetail() {
      let param = {
        id: "744966230582239232",
        current: this.page,
        size: this.pageSize
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/graphicList',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.honorList = res.data.data.records
          this.tableTotal = res.data.data.total
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    toDetail(item) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '协会荣誉详情',
        path: '/details'
      })

      this.$router.push({
        path: '/details',
        query: {
          pathObj: pathObj,
          contentId: item.contentId,
          typeId: '744966230582239232'
        }
      });
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getHonorDetail();
    },
    pageChange(val) {
      this.page = val
      this.getHonorDetail();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getHonorDetail()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getHonorDetail()
    },
  },
  mounted() {
    this.getHonorDetail()
  }

}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .hoverDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .hoverClass {
    background: #FFFFFF;
    width: 1200px;
    margin: 30px auto;
    overflow: hidden;
    .notice-title {
      clear: both;
      font-size: 35px;
      font-weight: bold;
      margin-top: 20px;
    }
    .honorContent {
      min-height: 409px;
      width: 1200px;
      margin: 30px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .honorList {
        margin: 20px;
        img{
          width: 360px;
          height: 260px;
        }
        .honorName {
          width: 360px;
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          .title {

          }
        }
      }
    }
  }
  .page {
    margin: 20px;
  }

</style>
